








import { Component, Vue } from "vue-property-decorator";
import EncuestasList from "@/components/Encuesta/EncuestasList.vue";

@Component({
  components: {
    EncuestasList
  },
  meta: {
    // sets document title
    title: "Listado de Encuestas - Municipalidad de Arica",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Aquí encontraras un listado de encuestas realizadas por la Municipalidad de Arica."
      },
      keywords: { name: "keywords", content: "Arica" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class EncuestasListView extends Vue {
  private mounted(){
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical"; 
    canonicalLink.href = 'https://muniarica.cl/listado/encuestas'; 
    document.head.appendChild(canonicalLink);
  }
}
